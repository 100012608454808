
.maincontainer .title_container .title{
    text-align: center;
    color: #fff;
    font-family: "Poppins";
    font-size: 34px;
    font-weight: bold;
    padding-top: 20px;
    margin-bottom: 20px;
    text-shadow: 0 0 5px  #c41c91, 0 0 15px #c41c91, 0 0 20px #c41c91,
    0 0 40px #7802af, 0 0 60px #7802af, 0 0 10px #7802af, 0 0 98px #000;
    color: #ffffff;
    text-align: center;
}
.dropdown {
    text-align: center;
    margin-bottom: 30px;
}
.dropdown .select {
    background: transparent;
    color: #fff;
    align-items: center;
    cursor: pointer;
    width: 280px;
    margin: 10px auto;
    position: relative;
}
.dropdown .select .list{
    position: absolute;
    z-index: 1;
    left: 0;
}
.dropdown .select p{
   text-align: start;
   padding: 10px;
   border: 1px solid #fff;
   line-height: 1;
   margin: 0;
}
.dropdown .list .options{
    list-style: none;
    color: #fff;
    padding: 4px 8px;
    background: #323741;
    border: 1px solid #363a43;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    position: relative;
    width: 280px;
    display: none;
}
.dropdown .select p:after{
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 28px;
}
.dropdown .select.open .list .options{
    display: block;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #ffff;
    text-align: start;
    margin: -1px 0 -1px;
    top: 2px;
}
.dropdown .list .options:hover{
    background: #4c515a;
    cursor: pointer;
}
.dropdown .select.open p:after{
    border-bottom: 6px solid #fff;
    border-top: 5px solid transparent;
    top: 12px;
}
.dropdown_container{
    margin-bottom: 30px;
}
.dropdown label{
    color: #00bceb;
    font-family: "Poppins";
    font-size: 20px;
}
.venue label{
    color: #fff;
    padding: 20px;
}
.title_container .game{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    text-align: center;
    padding: 10px;
    margin-bottom: 12px;
}
.title_container .gaming_wrapper p{
    padding: 10px 0;
    color: #fff;
}
.title_container .game img{
    height: 60px;
    border-radius: 36%;
}
.title_container .active img:hover{
    transform: scale(1.1);
    box-shadow: 2px 2px 2px #4b4b4b;
    cursor:pointer;
}
.title_container .active img{
    border: 1px solid #00dbff;
    transform: scale(1.1);
    box-shadow: 2px 2px 2px #4b4b4b;
}
.title_container .active .gaming_wrapper img:active{
    border: 1px solid #00dbff;
    transform: scale(1.1);
    box-shadow: 2px 2px 2px #4b4b4b;
}
.title_container .disable{
    opacity: 0.4;
    cursor: not-allowed;
}
.timings{
    display: grid;
    place-items: start;
    color: #00bceb;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 14px;
}
.timings p{
    margin-bottom: 0;
}
.timings label{
    color: #fff;
}
.form_box{
    background-color: #16161e;
    background-size: cover;
    position: relative;
    padding: 20px 0;
    border-radius: 10px;
    overflow: hidden;
    min-height: calc(100vh - 60px);
}
.form_box .heading{
    text-align: center;
    width: 100%;
    font-size: 25px;
    font-weight:bolder;
    margin-bottom: 20px;
}
.form_box .heading p{
    font-size: 26px;
    font-weight: 900;
    font-family: "Poppins";
    color: rgba(255, 255, 255, .4);
    background: linear-gradient(to right, #6970BA, #ffffff, #6970BA);
    background-clip: text;
    margin-bottom: 5px;
    margin-top: 30px;
}
.form_box .btn_box{
    max-width: 200px;
    margin: 0 auto;
    position: relative;
    border-radius: 50px;
    margin-bottom: 16px;
    border: 1px solid white;
    overflow: hidden;
}
.form_box .sliderbtn{
    padding: 8px 26px;
    background: transparent;
    cursor: pointer;
    border: 0;
    outline: none;
    position: relative;
    color: #fefefe;
    font-size: 16px;
    font-weight: bold;
    width: 50%;
}
.form_box .sliderbg{
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    position: absolute;
    border-radius: 30px;
    background: linear-gradient(to right, #B16CAC, #6970BA);
    transition: .5s;
}
.form_box.solo .sliderbg {
    top: 0;
    left: 50%;
}
.form_box .container{
    width: 100%;
    overflow: hidden;
}
.form_box .container .form_wrapper{
    width: 200%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: 0.5s;
}
.form_box .container .form_wrapper .form{
    width: 100%;
}
.form_box .container .form_wrapper .form.team_form {
    overflow: hidden;
}
.form_box .container .form_wrapper .mainteamform{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 200%;
    transition: 0.5s;
}

.form_box.solo .form_wrapper {
    transform: translateX(-50%);
}
.form_box .container .teamleader{
    display: grid;
    grid-template-columns: 1fr;
    gap: 18px;
    padding: 30px 30px 10px 30px;
}
.form_box .container .btn_container .backbtn{
    display: none;
}
.form_box .container .teammemberheading{
    text-align: center;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 8px;
    font-size: 32px;
}
.form_box .container .teammemberselect{
    display: flex;
    align-items: center;
    padding: 6px;
}
.form_box .container .teammemberselect p{
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 600;
    color: #fff;
}
.form_box .container .teammemberselect select{
    padding: 2px 12px;
    margin-bottom: 14px;
    margin-left: 18px;
    background: transparent;
    color: #fff;
    font-weight: 500;
    border-radius: 10px;
}
.form_box .container .teammemberselect select option{
    background-color: #000;
}
.form_box .container .teammembers{
    border-radius: 30px;
    background: rgba(255,255,255,.09);
}
.regnow{
    color: #fff;
    font-size: 36px;
    font-family: "Poppins";
    text-align: center;
    font-weight: 600;
}
.container .teamform{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.form_box .container .form_wrapper .team_member{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
    width: 100%;
}
.form_box .container .field_wrapper {
    position: relative;
}
.form_box .container .field_wrapper label {
    font-size: 13px;
    height: 40px;
    left: 0;
    color: #787373;
    padding: 12px 14px;
    pointer-events: none;
    position: absolute;
    text-align: left;
    top: 0;
    transition: top 0.1s linear;
    width: 100%;
}
.form_box .container .field_wrapper input {
    background: transparent;
    border: 2px solid #a3a3a3;
    border-radius: 8px;
    color: #dce0e6;
    font-size: 14px;
    height: 40px;
    outline: none;
    padding: 12px;
    width: 100%;
}
.form_box .container .field_wrapper.focus label {
    color: #ffffff;
}
.form_box .container .field_wrapper.filled label,
.form_box .container .field_wrapper.focus label {
    font-size: 12px;
    height: 16px;
    padding: 0 8px;
    top: -8px;
    width: auto;
    z-index: 1;
}
.form_box .container .field_wrapper.focus button{
    transform: translateX(-95%);
    transition: .5s;
}
.form_box .container .submit_btn {
    width: 100%;
    padding: 14px;
    margin: 10px 28px;
    display: block;
    font-family: "Poppins";
    cursor: pointer;
    background: linear-gradient(to right, #c41c91, #7802af);
    color: #fff;
    border: 0;
    outline: none;
    border-radius: 10px;
}

.radiogroup{
    padding: 0 20px;
    border: 1px solid #00e6ff;
    border-radius: 20px;
    margin-left: 10px;
    background: #080a0c;
    width: 250px;
    margin: 14px auto 0;
}
.radiogroup label{
    color: #fff;
    padding: 8px;
}
.btn_container{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}
.error_msg_cont{
    text-align: center;
    margin: 20px 0;
}
.error_msg{
    color: #f00;
}
.enrollbtn{
    display: grid;
    gap: 20px;
    place-items: center;
}
.enrollbtn .btn{
    width: 290px;
    padding: 12px;
    margin: 0;
    border-radius: 30px;
    font-weight: bolder;
    font-size: 16px;
}
.enrollbtn .btn:hover{
    background: linear-gradient(to right, #7802af, #c41c91);
}
.success_msg_cont{
    text-align: center;
    color: rgb(226, 213, 213);
    position: absolute;
    top: 50%;
    left: 16%;
    background: linear-gradient(to right, #55216c, #741b59);
    height: 120px;
    width: 250px;
    border-radius: 10px;
    display: grid;
    place-items: center;
}
.success_msg_cont .successmsg{
    padding: 8px 15px 0 0;
}
.success_msg_cont svg{
    height: 46px;
    width: 40px;
}
.svg_icons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
.canclesvg{
    padding: 8px;
    position: absolute;
    top: 0;
    right: 0px;
    cursor: pointer;
}

@supports (animation: grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
    .tick {
       stroke-opacity: 0;
       stroke-dasharray: 29px;
       stroke-dashoffset: 29px;
       animation: draw .5s cubic-bezier(.25, .25, .25, 1) forwards;
       animation-delay: .6s
   }

   .circle {
       fill-opacity: 0;
       stroke: #219a00;
       stroke-width: 16px;
       transform-origin: center;
       transform: scale(0);
       animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;
   }   
}

@keyframes grow {
   60% {
       transform: scale(.8);
       stroke-width: 4px;
       fill-opacity: 0;
   }
   100% {
       transform: scale(.9);
       stroke-width: 8px;
       fill-opacity: 1;
       fill: #219a00;
   }
}

@keyframes draw {
   0%, 100% { stroke-opacity: 1; }
   100% { stroke-dashoffset: 0; }
}

.form_box .container .submit_btn:hover {
    background: linear-gradient(to right, #7802af, #c41c91);
}
.form_box .container .solo_field{
    width: 100%;
    padding: 30px 30px 10px 30px;
}
.form_box .container .solo_field .field_wrapper{
    margin-bottom: 20px;
}

.form_box .container .mainteamform.teamformslider {
    transform: translateX(-50%);
}
.selectplayers p{
    text-align: center;
    padding: 20px 0;
}
.selectplayers p a{
    color: #00bceb;
}
.selectplayers p a:hover{
    color: #0066eb;
}


@media only screen and (min-width: 37.5em) {

    .maincontainer .title_container .title {
        font-size: 36px;
    }
    .timings{
        width: 360px;
        margin: auto;
    }
    .form_box .container .field_wrapper{
        width: 420px;
        margin: 10px auto;
    }
    .form_box .container .teammemberselect {
        padding: 12px 20px;
    }
    .form_box .container .form_wrapper .teammembers .team_member .field_wrapper{
        width: 260px;
    }
    .form_box .container .submit_btn {
        margin: 16px 90px;
    }
    .form_box .container .mainteamform .teammembers .btn_container{
        display: flex;
        flex-wrap: nowrap;
    }
    .form_box .container .mainteamform .teammembers .btn_container .submit_btn{
        margin: 30px;
        border-radius: 30px;
    }
    .success_msg_cont{
        left: 28%;
    }
    .success_msg_cont .successmsg {
        padding: 18px 12px 0 0;
    }   

}

@media only screen and (min-width: 48em) {

    .maincontainer .title_container{
        min-height: calc(100vh - 530px);
    }
    .form_box .container .teammemberheading {
        font-size: 38px;
    }
    .form_box .container .teamleader {
        padding: 30px 30px 10px 40px;
    }
    .form_box .container .form_wrapper .teammembers .team_member .field_wrapper{
        width: 320px;
    }
    .form_box .container .teammemberselect {
        padding: 10px 50px;
    }
    .form_box .container .submit_btn {
        margin: 20px 162px 0 176px;
    }
    .title_container .game {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .form_box .container .mainteamform .teammembers .btn_container .submit_btn{
        margin: 30px 60px;
    }
    .success_msg_cont{
        left: 34%;
    }
    .success_msg_cont .successmsg{
        font-size: 22px;
    }
    
}

@media only screen and (min-width: 64em) {
    .form_box .container .field_wrapper{
        width: 100%;
    }
    .maincontainer .title_container{
        width: 100%;
        margin-bottom: 50px;
    }
    .maincontainer .title_container .title{
        padding: 40px;
        font-weight: 600;
        font-size: 48px;
    }
    .title_container .dropdown{
        width: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 52px;
        place-items: center;
        margin: 0 auto 40px;
    }
    .title_container .dropdown .select{
        margin: 0;
        position: relative;
    }
    .title_container .dropdown .select p:after{
        position: absolute;
        top: 15px;
    }
    .title_container .dropdown .select.open p:after{
        top: 10px;
    }
    .title_container .dropdown .select.open .list .options{
        top: 2px;
    }
    .title_container .game{
        text-align: center;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    }
    .title_container .gaming_wrapper{
        color: #fff;
    }
    .title_container .game img{
        height:80px;
        border-radius: 36%;
    }
    .timings{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        width: 100%;
    }
    .form_box{
        width: 100%;
        border: 1px solid;
        color: #c9bdbd;
        position: relative;
        margin: 0 auto;
    }
    .form_box .container .form_wrapper{
        width: 200%;
        transition: 0.5s;
    }
    .form_box .container .form_wrapper .mainteamform{
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        width: 100%;
        transition: 0.5s;
    }
    .form_box .container .teamcontainer{
        height: 580px;
        transform: translateY(48%);
        transition: 0.8s;
        z-index: 1;
    }
    .regnow{
        text-align: center;
        font-size: 54px;
        font-weight: 600;
    }
    .form_box .line{
        border: 1px solid;
        color: #c9bdbd;
    }
    .form_box .container .form_wrapper .team_member{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 10px;
        width: 100%;
    }
    .form_box .container .teammembers{
        opacity: 0;
        height: 100%;
        border: 0.5px solid rgba(255, 255,255, .4);
    }
    .form_box .container .teamcontainer .teamleader{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px 40px;
        width: 100%;
    }
    .form_box .container .mainteamform.teamformslider .teamcontainer .teamleader{
        gap: 33px 13px;
        margin: auto;
    }
    .form_box .container .mainteamform.teamformslider .teammembers{
        opacity: 1;
        transition: 0.9s ease-in;
    }
    .form_box .container .mainteamform.teamformslider .continuebtn{
        display: none;
    }
    .form_box .container .solo_field{
        width: 400px;
        margin: 10px auto;
    }
    .form_box.solo .form_wrapper {
        transform: translateX(-50%);
    }
    .form_box .container .mainteamform.teamformslider .teamcontainer{
        transform: translateY(0);
    }
    .form_box .container .mainteamform.teamformslider {
        transform: translateY(0%);
    }
    .form_box .container .form_wrapper .mainteamform{
        display: grid;
        grid-template-columns: 4fr 6fr;
        padding: 10px;
        gap: 10px;
        height: 100%;
    }
    .form_box .container .teammemberheading{
        width: 380px;
        font-size: 41px;
        font-weight: 500;
    }
    .form_box .container .teammemberselect{
        padding: 20px;
    }
    .form_box .container .mainteamform.teamformslider .submit_btn.backbtn{
        margin-top: 22px;
        display: block;
    }
    .form_box .container .mainteamform.teamformslider  .teamcontainer .teamleader .field_wrapper{
        width: 190px;
    }
    .form_box .container .form_wrapper .teammembers .team_member .field_wrapper{
        width: 100%;
    }
    .form_box .container .teamcontainer .teamleader .field_wrapper{
        width: 200px;
    }
    .form_box .container .mainteamform.teamformslider .regnow{
        margin-top: 56px;
    }
    .teammembers .submit_btn.teammeberbackbtn{
        display: none;
    }
    .form_box .container .submit_btn {
        width: 200px;
        border-radius: 50px;
        margin: 20px auto;
    }
    .form_box .container .mainteamform .teammembers .btn_container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .radiogroup{
        margin:0 16px;
    }
    .selectplayer{
        display: flex;
        justify-content: center;
    }
    .dropdown {
        margin-bottom: 50px;
    }
    .dropdown_container{
        margin-bottom: 50px;
    }
    .tourdata_conatiner{
        margin-bottom: 50px;
    }
    .success_msg_cont{
        top: 40%;
        left: 40%;
    }
    .error_msg_cont{
        margin: 0;
    }
    .form_box .container .mainteamform .error_msg_cont{
        margin-bottom: 20px;
    }
    .form_box .container .solo_form .error_msg_cont{
        margin: 0;
    }
    .maincontainer .title_container .enroll_container .error_msg_cont{
        margin: 30px 0;
    }

}

@media only screen and (min-width: 80em) {
    .maincontainer{
        min-height: calc(100vh - 100px);
    }
    .maincontainer.regform{
        margin: 50px auto;
    }
    .maincontainer .title_container{
        width: 100%;
        margin-bottom: 50px;
    }
    .form_box{
        width: 90%;
        max-width: 1250px;  
    }
    .form_box .container .form_wrapper .mainteamform {
        padding: 10px 30px;
    }
    .form_box .container .teamleader .field_wrapper{
        width: 100%;
    }
    .form_box .container .teamcontainer{
        transform: translateX(68%);
    }
    .success_msg_cont{
        top: 36%;
        left: 40%;
    }
}
 
 .maincontainer{
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_box_border{
    background-size: cover;
    position: relative;
    padding: 3px;
    border-radius: 10px;
    overflow: hidden;
    min-height: 700px;
    width: 70%;
    border-radius: 30px;
    background: linear-gradient(to right, #B16CAC, #6970BA);
}
.form_box{
    overflow: hidden;
    position: absolute;
    min-width: calc(100% - 6px);
    min-height: calc(100% - 6px);
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background-color: rgba(22, 22, 30, .9);
    border-radius: 30px;
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    padding: 0;
    border: none;
}
.form_bg{
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background-color: rgba(22, 22, 30, .9);
    border-radius: 30px;
    object-fit: cover;
    opacity: 20%;
    filter: grayscale(1);
    pointer-events: none;
}
.rebtypo{
    filter: brightness(1000%);
    max-width: 300px;
}
.form_box .heading {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form_box .container{
    background: linear-gradient(to right, #B16CAC, #6970BA);
    overflow-y: scroll;
}
.form_wrapper{
    height: 100%;
    overflow: hidden;
}
.team_form{
    position: relative;
    height: 200%;
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.team_details{
    color: white;
    height: 100%;
}
.team_details > h1 {
    font-size: 32px;
    font-weight: 500;
    padding: 20px 40px;
    margin-top: 20px;
}
.form_box .container .field_wrapper input{
    border-color: white;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid white;
}
.form_box .container .field_wrapper label {
    color: white;
}
.form_box .btn_container{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
}
.form_wrapper button, .form_wrapper .submit_btn{
    margin: 20px 0;
    margin-bottom: 5px;
    padding: 10px 15px;
    border-radius: 24px;
    border: none;
    border: 4px solid #B16CAC;
    color: white;
    background-color: rgba(22, 22, 30, .6);
    cursor: pointer;
}
.form_box .container .solo_field{
    width: 100%;
    padding: 20px 40px;
}
.form_box .container .solo_field h1 {
    font-size: 32px;
    font-weight: 500;
    margin: 20px 0;
    color: white;
}
@media only screen and (max-width: 64em){
    .maincontainer{
        padding: 100px 0;
    }
    .form_box_border{
        width: 90%;
        min-height: 1200px;
    }
    .form_box{
        grid-template-columns: 1fr;
        grid-template-rows: 30% 70%;
    }
    .team_details h1 {
        padding: 20px;
    }
    .form_box .container .teamleader{
        padding: 20px;
    }
    .rebtypo {
        max-width: 250px;
    }
    .form_box .heading p{
        font-size: 18px;
    }
    .form_box .btn_box{
        transform: scale(70%);
    }
    .form_box .container .solo_field{
        padding: 20px;
    }
}