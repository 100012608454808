/* .home{
    min-height: calc(100svh - 56px);
    padding: 30px;
    display: flex;
}
.banner_text{
    display: grid;
    place-items: center;
    padding: 50px 0;
    text-align: center;
}
.home_banner h3{
    text-shadow: 0 0 5px  #c41c91, 0 0 15px #c41c91, 0 0 20px #c41c91,
    0 0 40px #7802af, 0 0 60px #7802af, 0 0 10px #7802af, 0 0 98px #000;
    color: #ffffff;
    text-align: center;
}
.home_banner .banner_btn {
    justify-content: center;
    background-color: transparent;
    border: 2px solid #e74c3c;
    border-radius: 0.6em;
    color: #e74c3c;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    padding: 12px;
    text-decoration: none;
    text-align: center;
    font-family: "Poppins";
    text-transform: uppercase;
      }
.home_banner .banner_btn:hover, .btn:focus {
    color: #fff;
    background: #e74c3c;
    outline: 0;
}
.banner_btns{
    display: grid;
    gap: 20px;
}

@media only screen and (min-width: 37.5em) {

}

@media only screen and (min-width: 48em) {
    .banner_btns {
    grid-template-columns: 1fr 1fr;
    gap: 0;
    }
}

@media only screen and (min-width: 64em) {
    .home{
        min-height: calc(100svh - 56px);
        max-width: 1600px;
        margin: 30px auto;
        display: grid;
        place-items: center;
    }
   
    .home_banner h3{
        font-size: 40px;
    }
    .home_banner .banner_text .banner_btn{
        padding: 1.2em 2.8em;
    }
}

@media only screen and (min-width: 80em) {
    
} */
.home {
    min-height: 100svh;
    position: relative;
    padding: 0 80px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0,0,0);
    background: linear-gradient(42deg, rgba(17, 23, 35, 0) 0%, rgba(17, 23, 35, 0) 52%,rgb(233, 123, 226) 52%, rgb(233, 123, 226) 52.6%,rgba(17, 23, 35, 0) 52.6%, rgba(17, 23, 35, 0) 54%, rgb(233, 123, 226) 54%, rgb(233, 123, 226) 70%, rgba(28,16,39,0) 70%, rgba(28,16,39,0) 72%, rgb(233, 123, 226) 72%, rgb(233, 123, 226) 73%, rgba(28,16,39,0) 73%, rgba(28,16,39,0) 88%); 
    overflow: hidden;
    transition: all 1s ease;
}
@keyframes gradient {
	0% {
		background-position-x: 50%;
	}
	100% {
		background-position-x: 60%;
	}
}
@keyframes onLoad {
	0% {
		background-position: 100% 100%;
        background-size: 200% 0%;
	}
	100% {
		background-position: 50% 50%;
        background-size: 200% 200%;

	}
}
.home .home_banner_img > img{
    position: absolute;
    right: 16%;
    bottom: 0px;
    max-width: 500px;
    background-size: 400%;
}
.home_banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}
.home_banner_info{
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    gap: 30px;
}
.home_banner_info h3{
    font-size: 56px;
    color: white;
    font-weight: 800;
    margin: 0;
    margin-bottom: 5px;
}
.home_banner_info h3 b{
    background-color: rgba(245,67,81,1);
    color: transparent;
    background-clip: text;
}
.home_banner_info > p{
    color: rgba(255, 255, 255, .5);
    margin-bottom: 30px;
    text-align: center;
    font-size: 18px;
}
.home_banner_info > img{
    mix-blend-mode: screen;
    filter: invert(1) brightness(1000%);
    max-width: 80%;
}
.home .banner_btns{
    display: flex;
    gap: 20px;
}
.banner_btns button{
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all .4s ease;
    font-weight: 600;
    background-color: transparent;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    text-transform: uppercase;
}
.banner_btns a:first-child button{
    color: white;
    border: none;
    background-color: rgb(233, 123, 226);
}
.banner_btns a:first-child button:hover{
    background-color: rgb(177, 68, 170);
    transform: scale(101%);
}
.banner_btns a:last-child button:hover{
    background-color: rgb(233, 123, 226);
    transform: scale(101%);
    color: white;
    border: 1px solid rgb(233, 123, 226);
}

@media only screen and (max-width: 64em){
    .home {
        min-height: 100svh;
        justify-content: center;
        padding: 0 30px;
        background: none;
    }
    .home_banner{
        align-items: center;
        justify-content: center;
    }
    .home_banner_info{
        max-width: 100%;
        gap: 20px;
    }
    .home_banner_info > img{
        max-width: 100%;
    }
    .home_banner_info > p{
        font-size: 14px;
        z-index: 10;
    }
    .home .banner_btns{
        flex-direction: column;
        align-items: center;
        z-index: 10;
    }
    .banner_btns button{
        padding: 10px;
        font-size: 16px;
        font-weight: 100;
    }
    .home .home_banner_img > img {
        width: 300px;
        filter: saturate(0);
        opacity: 10%;
    }
}