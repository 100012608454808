.sidebar{
    background: radial-gradient(circle at 24.1% 68.8%,rgba(0, 0, 0, 0.09) 0%, rgba(105, 70, 104, 0.2) 99.4%);
    backdrop-filter: blur(20px);
    color: #fff;
    position: fixed;
    z-index: 999;
    height: 100dvh;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.open{
  padding: 0;
}
.main .bars{
    display: flex;
    font-size: 25px;
    margin-left: 10px;
}
.link-wrapper{
    margin-top: -200px;
}
.link{
    display: flex;
    place-items: center;
    top: 20px;
    color: #fff;
    padding: 10px;
    gap: 15px;
    cursor: pointer;
    transition: all 0.5s;
    width: 100%;
    padding: 30px;
}
.link_text{
    font-size: 16px;
    color: rgb(240, 204, 237);
}
.sidebar .active{
    background: lightskyblue;
    color: #000;
}
.icon svg{
    width: 25px;
    height: 25px;
    fill: rgb(240, 204, 237);
}
.main .link_text {
    display: none;
}
.main .link_text {
    display: block;
}
.sidebar .bars{
    display: none;
}
.tooltip {
    position: relative;
    display: inline-block;
  }
  .top_section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
  }
  .top_section .top-section-bg{
    filter: brightness(400%) opacity(50%) saturate(90%);
    position: absolute;
    width: 120px;
    top: -10px;
  }
  .sidebar .top_section h1 {
    font-weight: 800;
    letter-spacing: 8px;
    font-size: 22px;
    color: #fff;
    z-index: 10;
  }
  .sidebar .top_section a{
    z-index: 10;
  }
  
.tooltip .tooltiptext {
    visibility: hidden;
    color: #fff;
    margin-left: 9px;
    margin-top: 4px;
    text-align: center;
    padding: 2px;
    position: absolute;
    box-shadow: 0 8px 8px -6px #d300ff;
    z-index: 1;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  .main .tooltip:hover .tooltiptext{
    visibility: hidden;
  }
  .sidebar .top_section{
    width: 100%;
    padding: 30px;
  }
   .top_section > p{
    color: #fff;
    z-index: 10;
  }
  .sidebar .account {
    display: flex;
    padding: 30px;
    gap: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
  }
  .logout{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: rgb(250, 124, 124);
    cursor: pointer;
  }
  .logout > div  {
    background-color: rgba(105, 70, 104, 0.2);
    border-radius: 50%;
    padding: 10px;
    display: grid;
    place-items: center;
  }
  .logout > p {
    margin-top: 5px;
    font-size: 12px;
  }
  .logout svg {
    height: 20px;
    width: 20px;
  }
 .dashboard-profile {
    display: flex;
  }
  .dashboard-profile .med {
    background-color: rgba(105, 70, 104, 0.2);
    padding: 10px;
    border-radius: 50%;
  }
  .dashboard-profile div > p {
    font-weight: 600;
    margin: 2px 10px;
  }
  .sidebar ul{
    margin: 0;
  }
  .dashboard-profile div > p:last-child{
    opacity: 50%;
    font-weight: 100;
    font-size: 13px;
    margin: 0px 10px;
  }
  .dashboard-profile > .med > svg{
    width: 30px;
  }
  .tab-active {
    background: linear-gradient(to right, rgba(177, 108, 172, .1) 95.2%, rgba(177, 108, 172, .2) 97.2%, rgba(208, 139, 203, .3));
    border-right: 3px solid #b16cac;
  }
@media only screen and (min-width: 64em) {
   .open{
        display: flex;
        flex-direction: column;
        padding-top: 80px;
    }
    .main .sidebar{
        width: 350px;
    }
    .link{
        top: 50px;
    }
  }
  @media only screen and (max-width: 64em){
  .bars{
      display: block;
      padding: 0 3px;
  }
 .top_section  .top-section-bg{
    width: 90px;
    top: 0;
  }
  .sidebar .top_section > h1 {
    font-size: 18px;
    letter-spacing: 6px;
  }
  .sidebar p{
    margin: 0;
  }
  .sidebar .link {
    padding: 20px;
  }
  .sidebar .link_text{
    font-size: 12px;
  }
  .sidebar .link .icon svg{
    width: 18px;
  }
  .sidebar{
    left: -100%;
  }
  .sidebar .bars{
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
  }
  .sidebar .bars svg{
    color: white;
    width: 15px;
    filter: invert(1);
  }
  .open{
    left: 0;
  }
  .sidebar .account {
    padding: 20px;
  }
  .dashboard-profile > .med > svg{
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  .dashboard-profile div > p {
    font-size: 14px;
    margin-bottom: 1px;
  }
  .dashboard-profile div > p:last-child{
    font-size: 11px;
  }
}