.min_height{
    min-height: calc(100vh - 90px);
}
.mx-width{
    display: flex;
}
.playersdetails p{
    text-align: center;
    font-size: 20px;
    color: #fff;
    padding: 16px;
}
.noplayers{
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 20px;
    color: #fff;
    padding: 16px;
}
.btnreg{
    width: auto;
}
.players .heading{
    font-size: 26px;
    color: rgb(255, 255, 255);
    margin: 10px;
}
.team{
    margin-bottom: 40px;
    display: grid;
}
.players{
    margin-bottom: 30px ;
    display: grid;
    place-items: center;
    text-align: center;
}
.editbtn{
    margin: 10px auto;
}
table {
    margin-bottom: 20px;
}
table.border th,
table.border td {

    color: #f00;
    font-size: 14px;
}
table.border td label{
    color: #fff;
}
table th,
table td {
    font-size: 16px;
    padding: 5px 12px;
}
.btns_container{
    display: grid;
    gap: 20px;
}

@media only screen and (min-width: 48em) {
.players {
    place-items: flex-start;
    text-align: center;
}
.playersdetails p {
    font-size: 24px;
}
.players .team_players, .soloplayer{
    display: flex;
    gap: 30px;
}
}

@media only screen and (min-width: 64em) {
    .playersdetails p{
        font-size: 34px;
    }
    .cntleft {
        width: 300px;
    }
    .cntcenter {
        width: calc(100% - 540px);
    }
    .sitenav .item.mob {
        padding: 0;
    }
}
.player-card-border {
    background: linear-gradient(to right, #6a70ba 0%, #B16CAC 20%, #6a70ba 40%, #B16CAC 60%, #6a70ba 80%,#B16CAC 100% );
    background-size: 800% 800%;
    animation: btnAnimation 30s infinite alternate;
    padding: 4px;
    position: relative;
    min-height: 200px;
    min-width: 300px;
    border-radius: 20px;
    overflow: hidden;
    margin: 50px 0;
    transform: rotate3d(10deg);
}
.player-card-border > span {
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    background: linear-gradient(to right, #B16CAC -80%, #6970BA);
    box-shadow: -11px 14px 21px 1px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: 800;
    color: #1E1E1E;
    z-index: 3;
}
.player-card{
    background-color: #1E1E1E;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    border-radius: 20px;
    padding: 30px 10px;
    padding-right: 50px;
    text-align: left;
}
.team {
    margin-bottom: 0;
}
table.border th, table.border td{
    color: white;
}
.player-card button {
    color: white;
    padding: 5px 10px;
    background-color: #1E1E1E;
    border: none;
    position: relative;
    padding-top: 9px;
    z-index: 5;
    cursor: pointer;
}
.player-card button::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background: linear-gradient(to right, #6970BA 0%, #B16CAC 20%, #6970BA 40%, #B16CAC 60%, #6970BA 80%,#B16CAC 100% );
    background-size: 400% 400%;
    animation: btnAnimation 5s infinite alternate;
    z-index: -10;
    border-radius: 6px;
}
.player-card button::after{
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    height: calc(100% + 0px);
    width: calc(100% + 0px);
    background-color: #1E1E1E;
    border-radius: 6px;
    z-index: -10;
}
@keyframes btnAnimation {
    0%, 100%{
        background-position-x: 0%;
    }
    50% {
        background-position-x: 100%;
    }
}