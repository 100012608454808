/* body.hb-open {
    overflow: hidden;
}
.header {
    border-bottom: 1px solid #fff;
    margin: 0;
    padding: 0;
    z-index: 10;
    transition: top .3s ease-in-out;
}
.cntleft {
    display: grid;
    grid-template-columns: 300px auto;
    width: 25px;
    gap: 15px;
}
.sitenav {
    display: none;
}
.sitenav .sidebar{
    display: block;
    background: none;
}
.sitenav .item.mob{
    display: grid;
    grid-template-columns: 40px auto auto;
    padding: 10px 20px;
    gap: 2px;
    place-items: center;
}
.sitenav .mob{
    padding: 0 20px;
    margin-top: 10px;
}
.mob .logout p{
    font-size: 20px;
    padding: 4px 12px;
    font-weight: 500;
    color: #c41c91;
}
.sitenav .mob a{
    width: 160px;
}
.sitenav .mob .med{
    border: 2px solid #7802af;
    position: relative;
    text-align: center;
    height: fit-content;
    padding: 2px;
    width: 100%;
}
.sitenav .mob .cnt{
    padding: 0 10px;
}
.sitenav .mob .cnt p{
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}
.header.hb-open .sitenav {
    display: grid;
    position: absolute;
    top: 45px;
    height: 100%;
    width: 75%;
    z-index: 2;
    left: 0;
    align-content: flex-start;
    background-color: #1d1e23;
}
.sitenav li {
    padding: 5px 14px;
    margin: 0;
    position: relative;
    list-style-type: none;
    max-height: 52px;
    text-transform: uppercase;
    height: 100%;
    display: inline-grid;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid #ccc;
}
.sitenav li.active::before {
    background: #0078f2;
    height: 5px;
    content: '';
    left: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
}
.sitenav li p {
    color: #ccc;
    position: relative;
    margin: 0;
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    background:
    linear-gradient(currentColor 0 0) var(--p, 100%) 0,
    linear-gradient(currentColor 0 0) 0 var(--d, 0),
    linear-gradient(currentColor 0 0) var(--d, 0) 100%,
    linear-gradient(currentColor 0 0) 100% var(--p, 100%);
    background-size: var(--d, 0) 2px, 2px var(--d, 0);
    background-repeat: no-repeat;
    transition: 0.5s, background-position 0s 0.5s;
}
.sitenav li p:hover {
--d: 100%;
--p: 0%;
}
.header .hb_menu{
    bottom: 0;
    left: 0;
    max-width: 300px;
    overflow: hidden;
    right: 0;
    top: 0;
    z-index: 1;
}
.hb_menu {
    height: 50px;
    padding: 12.5px 0;
    position: absolute;
    left: 20px;
    margin-left: 15px;
    top: 0;
    width: 25px;
}
.hb_menu span {
    display: block;
    position: relative;
    top: 8px;
    transition: transform .25s ease-in-out .15s,-webkit-transform .25s ease-in-out .15s;
}
.hb_menu span,
.hb_menu span::before,
.hb_menu span::after {
    background: #f2f2f2;
    border-radius: 10px;
    height: 3px;
    width: 25px;
}
.hb_menu span::before {
    content: '';
    position: absolute;
    top: -8px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;

}
.hb_menu span::after {
    content: '';
    position: absolute;
    top: 8px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header.hb-open .hb_menu span {
    transform: rotate(-45deg);
}
.header.hb-open .hb_menu span::before,
.header.hb-open .hb_menu span::after{
    top: 0;
    transform: rotate(90deg);
}
.header.hb-open .hb_menu {
    transform: translateX(0);
}
.header.hb-open .hb_mask {
    content: '';
    background: rgba(0,0,0,0.3);
    bottom: 0;
    left: 0;
    pointer-events: auto;
    position: fixed;
    right: 0;
    top: 53px;
}
.cntcenter {
    width: calc(100% - 25px);
}
.cntcenter .logo {
    align-items: center;
    display: grid;
    justify-content: center;
    gap: 0;
    height: 100%;
}
.cntcenter .imglogo {
    width: 40px;
    cursor: pointer;
    display: none;
}
.cntcenter .textlogo {
    width: 125px;
    cursor: pointer;
    margin-top: 10px;
}
.cntright {
    padding: 8px 0;
    width: 148px;
    display: none;
}
.signbtn{
    font-family: "Poppins";
}


@media only screen  and (min-width: 37.5em) {
    .header {
        width: 100%;
    }
    .cntleft {
        width: 120px;
    }
    .hb_menu {
        padding: 14.5px 0;
    }
    .cntright {
        display: inline;
        font-size: 10px;
    }
    .cntcenter {
        width: calc(100% - 145px);
    }
    .cntcenter .textlogo {
        width: 142px;
    }
    .cntcenter .logo {
        padding-right: 87px;
    }
    .cntcenter {
        width: calc(100% - 240px);
    }
    .hb_menu span,
    .hb_menu span::before,
    .hb_menu span::after {
       display: block;
    }
    .header.hb-open .sitenav {
        top: 60px;
    }
}

@media only screen and (min-width: 48em) {
    .cntright {
        text-align: right;
        width: 30%;
        display: none;
    }
    .hb_menu {
        padding: 25.5px 0;
    }
    .header.hb-open .sitenav {
        top: 84px;
    }
    .header.hb-open .hb_mask {
        top: 82px;
    }
    .sitenav .item.mob {
        place-items: flex-start;
    }
    .sitenav .mob .cnt {
        padding: 8px;
    }
    .mob .logout p {
        font-size: 24px;
    }
    .sitenav li {
        padding: 5px;
    }
    .cntcenter .logo {
    align-items: center;
    display: grid;
    grid-template-columns: 60px auto;
    justify-content: center;
    gap: 6px;
    height: 100%;
    padding: 0;
    }
    .cntcenter .imglogo {
        width: 100%;
        display: block;
    }
    .cntcenter .textlogo {
        width: 200px;
        margin-left: 10px;
    }
    
}

@media only screen and (min-width: 64em) {
    .header .hb_menu{
        display: none;
    }
    .sitenav .tab p {
        padding: 5px;
        border: none;
    }
    .sitenav .mob{
        padding: 0;
    }
    .sitenav .mob .cnt {
        padding: 0;
    }
    .account.mob{
        display: grid;
        grid-template-columns: 40px auto auto;
    }
    .logout{
        cursor: pointer;
    }
    .logout p{
        font-size: 20px;
        padding: 4px 12px;
        font-weight: 500;
        color: #c41c91;
    }
    .logout svg{
        margin: -5px 0px;
    }
    .logout p:hover{
        color: #7802af;
    }
    .sitenav .mob a{
        display: none;
    }
    .sitenav .mob .cnt p {
        font-size: 20px;
        padding: 4px 12px;
        font-weight: 500;
    }
    .sitenav .sidebar {
        display: none;
    }
    .cnt{
        color: #fff;
    }
    .header .cntleft .sitenav {
        display: flex;
        position: absolute;
        top: 20px;
    }
    .header .cntleft .sitenav li {
        display: block;
    }
    .cntcenter .logo {
        height: 100%;
    }
    .cntcenter .logo .imglogo {
        width: 70px;
        cursor: pointer;
        padding: 5px;
        display: block;
    }
    .cntcenter .logo .textlogo {
        width: 200px;
        height: 54px;
        cursor: pointer;
        grid-template-columns: 60px auto;
        padding: 5px;
    }
    .cntright {
        text-align: right;
        width: 240px;
        margin: 14px auto;
        display: block;
    } 
    .sitenav li {
        padding: 10px;
        border: none;
    }
    .header.hb-open .sitenav {
        display: none;
    }
    .header.hb-open .hb_mask {
        display: none;
    }
    .hb_menu span,
    .hb_menu span::before,
    .hb_menu span::after {
       display: none;
    }
    .signbtn{
        padding: 10px;
        margin: 0;
        background: linear-gradient(to right, #c41c91, #7802af);
        color: #fff;
        border-radius: 10px;
        font-weight: bolder;
        font-size: 16px;
    } 
}

@media only screen and (min-width: 80em){

    .cntcenter .logo {
        align-items: center;
        display: grid;
        grid-template-columns: 60px auto;
        justify-content: center;
        height: 100%;
        margin-bottom: 0;
    }
    .cntcenter .textlogo {
        width: 200px;
        cursor: pointer;
    }
    .cntcenter {
        height: 100%;
    }
    .cntright {
        width: 200px;
        padding-right: 20px;
        display: block;
    }
} */
.content_wrapper{
    position: relative;
}
.header{
    display: none;
}
 .nav-bar{
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: fixed;
    z-index: 89;
}
.nav-bar-open {
    display: none;
}
.bars{
    color: white;
    opacity: 70%;
}
@media only screen and (max-width: 64em){
    .header{
        display: flex;
    }
}