.formheight {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-content: center;
  padding: 100px 0;
}
.user_auth {
  min-height: 1000px;
  width: 1100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px rgba(255, 255, 255, 0.1) solid;
  border-radius: 16px;
}
.login {
  width: 950px;
  min-height: 600px;
}
.user-auth-border {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0 auto;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  background-size: cover;
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  transition: all 500ms ease-in-out;
  z-index: 2;
  overflow: hidden;
  border: 1px rgba(255, 255, 255, 0.1) solid;
  border-radius: 16px;
}
.login-reb-logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.login-reb-logo::before,
.signup-reb-logo::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(28, 16, 39, 0.6) 100%
  );
  z-index: 2;
}
.login-reb-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.signup .login-reb-img {
  object-fit: contain;
  transform: scale(170%);
}
.login-reb-logo > div {
  position: relative;
  z-index: 2;
  pointer-events: none;
  color: white;
}
.login-reb-logo .title h1,
.signup-reb-logo .title h1 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 5px;
  z-index: 1;
  text-align: center;
}
.login-reb-logo.title h1 p,
.signup-reb-logo .title h1 p {
  font-weight: 100;
  font-size: 12px;
}
.user_auth .med > a > img {
  width: 150px;
  object-fit: contain;
  filter: saturate(100%) brightness(400%);
  margin-bottom: 30px;
}

/* FORM */

.auth_details {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(28, 16, 39, 1);
  background-position: 0% 0%;
  background-repeat: no-repeat;
  flex-direction: column;
  padding: 20px 0;
}
.auth_details::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(
    1200px circle at var(--mouse-x) var(--mouse-y),
    rgba(168, 111, 165, 0.2),
    transparent 90%
  );
  z-index: 2;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.user_auth:hover .auth_details::after,
.user_auth:hover .user-auth-border::after {
  opacity: 1;
}
.auth_details :where(a, button, input, .show, .hide) {
  position: relative;
  z-index: 5;
}
.auth_details h2 {
  color: rgb(255, 255, 255);
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 6px;
}
.auth_details h2 svg {
  fill: rgb(255, 255, 255);
  margin-right: 10px;
}
/* 
INPUT STYLES
 */
.user_auth .login_details,
.user_auth .otp-details {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.user_auth .login_error {
  color: red;
  display: block;
  font-size: 12px;
  margin-top: 10px;
}
.user_auth.login .forgot {
  margin: 5px 0 0;
  text-align: right;
  width: 100%;
}
.user_auth .auth_details .userid {
  width: 250px;
}
.user_auth .hide {
  display: none;
}
.user_auth.login .login_details #login {
  margin-right: 20px;
}
.user_auth .field_wrapper {
  margin: 20px 0;
  position: relative;
}
.user_auth .field_wrapper label {
  font-size: 17px;
  color: rgba(168, 111, 165, 1);
  left: 0;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 20px;
  transition: top 0.1s linear;
  width: 100%;
}
.user_auth .field_wrapper.filled label,
.user_auth .field_wrapper.focus label {
  font-size: 10px;
  height: 16px;
  left: 0;
  padding: 3px 5px;
  top: -8px;
  width: auto;
  z-index: 1;
}
.user_auth .field_wrapper input {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(168, 111, 165, 1);
  color: rgb(240, 204, 237);
  font-size: 16px;
  height: 40px;
  outline: none;
  padding: 0;
  width: 100%;
  margin: 10px 0;
  position: relative;
}
.user_auth .field_wrapper input::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 3000px;
  width: 100%;
  background-color: #fff;
  z-index: 20;
}
.user_auth .field_wrapper .warning {
  color: red;
  display: none;
  font-size: 10px;
  margin: 5px 0 0 5px;
}
.user_auth .field_wrapper.warn .warning {
  display: block;
}
.user_auth .field_wrapper .toggle_pwd {
  height: 24px;
  position: absolute;
  right: 10px;
  top: 8px;
  width: 24px;
}
.user_auth .field_wrapper .toggle_pwd svg {
  display: none;
  cursor: pointer;
  fill: rgba(168, 111, 165, 1);
  position: relative;
}
.user_auth .field_wrapper .toggle_pwd svg.show,
.user_auth .field_wrapper.show-pwd .toggle_pwd svg.hide {
  display: block;
}
.user_auth .field_wrapper.show-pwd .toggle_pwd svg.show {
  display: none;
}

.user_auth .otp-details .back {
  cursor: pointer;
}
.login-page .util {
  text-align: left;
  width: 80%;
  color: rgba(168, 111, 165, 1);
  margin: 0;
  margin-bottom: 5px;
}
.util > button {
  margin-bottom: 15px;
}
.util {
  color: white;
}
.auth_details a:hover,
.auth_details button:hover {
  color: #ff38e8;
}
.otp_login {
  width: 80%;
}
@media only screen and (max-width: 64em) {
  .formheight{
    position: relative;
  }
  .login,
  .signup {
    margin: 0;
    width: calc(100vw - 0px);
    height: 800px;
  }
  .signup {
    height: 1200px;
  }
  .formheight{
    padding: 0;
  }
  .user_auth {
    position: static;
  }
  .user-auth-border {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 30% 70%;
    border: none;
    border-radius: 0px;
    width: 100%;
    height: 100%;
  }
  .user_auth .med > a > img {
    width: 80px;
  }
  .login_details > h2 > svg {
    width: 18px;
  }
  .login-reb-img {
    object-fit: contain;
    transform: scale(300%);
  }
  .auth_details{
    height: 100%;
  }
  .auth_details h2 {
    font-size: 18px;
    display: flex;
    align-items: baseline;
    font-weight: 600;
    letter-spacing: 16px;
  }
  .auth_details h2 > svg {
    height: 16px;
    margin-right: 5px;
  }
  .login-reb-logo::before,
  .signup-reb-logo::before {
    background: linear-gradient(
        217deg,
        rgba(49, 13, 53, 0.8),
        rgba(4, 0, 255, 0) 70.71%
      ),
      linear-gradient(
        127deg,
        rgba(20, 5, 26, 0.6),
        rgba(0, 248, 236, 0.1) 70.71%
      ),
      linear-gradient(336deg, rgb(128, 18, 60), rgba(0, 0, 255, 0) 70.71%);
  }
  .auth_details::after {
    background: none;
  }
  .user_auth .field_wrapper label{
    font-size: 14px;
  }
}
