.footer {
    width: 100%;
}
.fatfooter {
    background: rgba(168, 111, 165, .1);
    padding: 30px 0;
    width: 100%;
}
.footerinfo {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr;
}
.footerinfo h4,
.footerinfo p {
    color: #ccc;
}
.footerinfo h4 {
    font-size: 16px;
}
.footerinfo p {
    font-size: 14px;
}
.followus {
    text-align: center;
}
.followus h6 {
    color: rgba(208,208,208,0.8);
    font-size: 14px;
    margin-bottom: 10px;
}
.sociallinks {
    display: inline-grid;
    gap: 15px;
    grid-template-columns: repeat(5, 1fr);
}
.sociallinks a {
    line-height: 0;
}
.sociallinks a svg path {
    fill: #ddd;
}
.sociallinks a:hover svg path {
    fill: #ff3ae9;
}
.sociallinks a:last-child {
    margin-right: 0;
}
.sociallinks a .fa {
    font-size: 25px;
}
.ftcontacts .mobile svg {
    fill: #fff;
}
.ftcontacts{
    text-align: center;
}
.ftcontacts .mobile a {
    color: #fff;
}
.legalfooter {
    background: rgba(26, 15, 25, 0.4);
    width: 100%;
}
.footerlinks {
    padding-bottom: 30px;
    padding-top: 30px;
}
.ftcnt {
    display: flex;
    flex-direction: column-reverse;
}
.rightscontent {
    margin: 0 0 30px;
    text-align: center;
}
.logo {
    text-align: center;
    filter: brightness(400%);
}
.logo .footerimglogo {
    cursor: pointer;
    width: 100px;
    margin: 12px;
}
.legalfooter p,
.legalfooter a {
    color: #727272;
}
.disclaimer p {
    font-size: 12px;
    text-align: center;
}

@media only screen and (min-width: 37.5em) {
    .footerinfo {
        grid-template-columns: 1fr 1fr;
    }
    .followus {
        text-align: center;
    }
    .ftcnt {
         align-items: center;
        margin-bottom: 20px;
    }
    .rightscontent,
    .legalfooter .logo {
        margin: 0;
        width: calc(100% / 3);
        padding: 10px;
    }
    .rightscontent {
        text-align: center;
        width: 250px;
    }
}

@media only screen and (min-width: 48em) {
    .fatfooter {
        padding: 50px 0;
    }
}

@media only screen and (min-width: 64em) {
    .footer {
        margin-bottom: 0;
    }
    .fatfooter {
        padding: 80px 0;
    }
    .logo img {
        cursor: pointer;
        width: 100px;
    }
  .fatfooter .footerinfo{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        grid-gap: 10px;
        text-align-last: center;
    }
}

@media only screen and (min-width: 80em) {
    .ftcontacts a {
        font-size: 18px;
    }
    .ftcnt {
        align-items: center;
       margin-top: 20px;
   }
    .rightscontent {
        width: 100%;
        padding: 0;
    }
    .legalfooter .logo {
        margin: 0;
        width: calc(100% / 3);
        text-align: center;
    }
}